import type { ComputedRef, Ref } from 'vue'
import { getProductPrice } from 'lib/product'
import type { Environment } from 'types/environment'
import type { ProductVariant } from 'types/models/product'

export function useProductPrice(currency: Ref<Environment.Currency>, variant: Ref<ProductVariant> | ProductVariant): {
  isDiscounted: ComputedRef<boolean>
  originalPrice: ComputedRef<number>
  finalPrice: ComputedRef<number>
} {
  const prices = computed(() => getProductPrice(unref(currency), unref(variant)))

  return {
    isDiscounted: computed(() => prices.value.finalPrice < prices.value.originalPrice),
    originalPrice: computed(() => prices.value.originalPrice),
    finalPrice: computed(() => prices.value.finalPrice),
  }
}
