<script setup lang="ts">
import type { ProductVariant } from 'types/models/product'

interface Props {
  variants: ProductVariant[]
  selected?: ProductVariant
  forceOpen: boolean
}

const props = withDefaults(defineProps<Props>(), {
  selected: undefined,
  forceOpen: false,
})

const emit = defineEmits(['selectionClosed', 'changeVariant'])
const gtm = useGTM()
const { $t } = useNuxtApp()

const selectText = ref<HTMLInputElement>()
const inputContainer = ref<HTMLInputElement>()
const variantsDropdown = ref<HTMLUListElement>()
const gridContainer = ref(null)
const sizeButton = ref(null)
const isVariantsExpanded = ref(false)

function clearVariantSelection() {
  if (!selectText.value || !inputContainer.value)
    return
  selectText.value.value = ''
  inputContainer.value.classList.remove('variant-selected')

  if (props.variants.length === 1)
    selectText.value.value = props.variants[0]!.size
}

onMounted(() => {
  clearVariantSelection()
  setDropdownItemHeight()
})

watch(
  () => props.variants,
  () => {
    clearVariantSelection()
  },
)

watch(
  () => props.forceOpen,
  () => {
    if (props.forceOpen)
      isVariantsExpanded.value = true
  },
)
function hideVariantsList() {
  isVariantsExpanded.value = false
  emit('selectionClosed')
}

onClickOutside(gridContainer, (e: MouseEvent) => {
  if (e.target === sizeButton.value)
    return
  hideVariantsList()
})

function toggleVariantsList() {
  isVariantsExpanded.value = !isVariantsExpanded.value
  if (!isVariantsExpanded.value)
    emit('selectionClosed')
}

function changeVariant(variant: ProductVariant) {
  emit('changeVariant', variant)
  gtm.pushProductPageEvent({ action: 'sizeSelection', label: variant.size })
}

function closeVariantsList(variant: ProductVariant) {
  hideVariantsList()
  showSelection(variant.size)
}

function showSelection(variant: string) {
  if (!selectText.value || !inputContainer.value)
    return
  inputContainer.value.classList.add('variant-selected')
  selectText.value.value = variant
}

function setDropdownItemHeight() {
  if (!variantsDropdown.value)
    return
  const height = props.variants.length * 40
  variantsDropdown.value.style.setProperty('--list-height', `${height}px`)
}
</script>

<template>
  <div
    role="menu"
    class="size-selector" :class="[
      { 'one-size': variants.length === 1, 'variants-expanded': isVariantsExpanded },
    ]"
  >
    <span ref="inputContainer" class="input-container">
      <input
        ref="selectText"
        type="text"
        class="select-text"
        aria-hidden="true"
        tabindex="-1"
        :placeholder="$t('sizeSelectorTag')"
        readonly
      >
      <button
        ref="sizeButton"
        class="size-selector-btn"
        aria-haspopup="menu"
        :aria-label="$t('sizeSelector')"
        @click.prevent="toggleVariantsList"
      />
    </span>
    <ul
      v-if="variants.length > 1"
      ref="variantsDropdown"
      role="menu"
      data-test="size-options-list"
      :aria-label="$t('availableSizes')"
      class="variant-dropdown" :class="[{ 'default-selected': selected === undefined }]"
    >
      <li
        v-for="variant in variants"
        :key="`variant-dropdown-${variant.id}`"
        aria-haspopup="menu"
        data-test="size-option"
        :class="{ 'variant-outstock': variant.stockStatus === 'no-stock' }"
      >
        <label
          class="variant" :class="[
            {
              'selected': selected && selected.sku === variant.sku,
              'no-stock': variant.stockStatus === 'no-stock',
            },
          ]"
        >
          <input
            name="size"
            type="radio"
            :value="variant.size"
            :checked="selected && selected.sku === variant.sku"
            :aria-checked="selected && selected.sku === variant.sku"
            :disabled="variant.stockStatus === 'no-stock'"
            @change="changeVariant(variant)"
            @click="closeVariantsList(variant)"
          >
          <div class="stock-status">
            <span :class="[{ disabled: variant.stockStatus === 'no-stock' }]">
              {{ variant.size }}
            </span>
            <span v-if="variant.stockStatus === 'low-stock'"> {{ $t('fewLeft') }} </span>
            <span v-else-if="variant.stockStatus === 'no-stock'"> {{ $t('outOfStock') }} </span>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 0 !important;
}
input[type='radio'] {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}
.size-selector {
  height: 4rem;
  font-size: 2rem;
  min-width: 15rem;
  position: relative;

  .input-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    grid-template: 'select-text select-icon'/1fr 3rem;
    background-color: var(--white);
    border-radius: 2px;
    border: none;

    &::after {
      content: '';
      display: block;
      height: 1.6rem;
      width: 0.933rem;
      grid-area: select-icon;
      transform: rotate(-90deg);
      justify-self: start;
      align-self: center;
      pointer-events: none;
      background: url('/icons/left-arrow.svg') center center no-repeat;
    }
    &.variant-selected {
      background-color: var(--yellow);
    }
    &.variant-selected .select-text {
      text-align: center;
    }
    .size-selector-btn {
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  .select-text {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-left: 2rem;
    grid-area: select-text;
    background-color: transparent;
    font-size: 1.4rem;

    &::placeholder {
      opacity: 1;
      color: var(--black);
    }
  }

  &.one-size {
    pointer-events: none;
    .input-container {
      background-color: var(--yellow);
      grid-template: 'select-text'/1fr;
      &::after {
        content: '';
        display: none;
      }
      .select-text {
        padding: 1rem;
        text-align: center;
      }
      .size-selector-btn {
        cursor: default;
      }
    }
  }

  .variant-dropdown {
    visibility: hidden;
    z-index: 1;
  }

  .variant-outstock {
    background-color: var(--gray);
  }
}

.stock-status {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & .disabled {
    background: var(--gray);
    text-decoration: line-through;
    cursor: default;
  }
}
  .size-selector .select-text {
    padding-left: 3rem;
  }
  .size-selector .variant-dropdown {
    top: 100%;
    width: 100%;
    max-height: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    transition: max-height linear 0.1s;
  }
  .size-selector.variants-expanded .variant-dropdown {
    visibility: visible;
    background-color: var(--white);
    max-height: var(--list-height, 30rem);
    border-radius: 2px;
  }
  .variant {
    width: 100%;
    height: 4rem;
    display: flex;
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
    align-items: center;
    padding: 1rem 2rem 1rem 3rem;
    cursor: default;

    &:not(.no-stock):hover {
      background-color: var(--yellow);
      cursor: pointer;
    }
    &:first-of-type {
      border-top: 1px solid var(--gray-light);
    }
  }
</style>
